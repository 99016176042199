
import { Watch, Mixins, Component, ProvideReactive } from "vue-property-decorator";
import ETable from "@/components/ETable.vue";

import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { healthRecordGroupDetail, groupDetailMember, groupDetailMemberDetection } from "@/api/healthRecord";

const curDomain = `${window.location.origin}`;

@Component({
  inject: [],
  components: { ETable }
})
export default class HealthRecordDetail extends Mixins(loading, tablePagination, Resize) {
    @ProvideReactive() currentTab = "2";
    
    id = this.$route.query.id;
    
    groupDetail = {} as any;

    columns = [
        { label: "分值", prop: "medicineTotal" },
        { label: "诊断结果", prop: "result" },
        { label: "检测时间", prop: "createTime" },
        { label: "操作", prop: "action" },
    ]

    memberList = []
    currentMemberId = ""
    itemTotal = 0
    tableData = []

    mounted() {
        this.windowResize(500);
        this.getDetail();
    }

    getDetail() {
        if (!this.id) return;
        healthRecordGroupDetail({ id: this.id }).then((res) => {
            this.groupDetail = res.data;
        })
        groupDetailMember({ id: this.id }).then((res) => {
            this.memberList = res.data || [];
            this.currentMemberId = res.data?.[0].id;
        })
    }

    selectMember(item) {
        this.currentMemberId = item.id;
        this.getMemberDetectionList();
    }

    getData() {
        groupDetailMemberDetection({ pageNum: this.currentPage, pageSize: this.pageSize, memberId: this.currentMemberId  }).then((res) => {
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        })
    }

    @Watch("currentMemberId", { immediate: true })
    getMemberDetectionList() {
        if (!this.currentMemberId) return;
        this.getData();
    }

    openReport(row) {
        const url = `${curDomain}/h5/#/tongue/healthTongueResult?id=${row.id}&groupMemberId=${this.currentMemberId}`;
        window.open(url, "_blank")
    }
    
}
